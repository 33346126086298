<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        Şifrənin yenilənməsi
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="resetPasswordForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Köhnə şifrə"
              filled
              required
              maxlength="20"
              minlength="6"
              v-model="formData.oldPassword"
              :rules="formRules.oldPassword"
              :type="isOldPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isOldPasswordVisible)"
              @click:append="toggleOldPasswordVisibility"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Yeni şifrə"
              filled
              required
              maxlength="20"
              minlength="6"
              v-model="formData.password"
              :rules="formRules.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordVisible)"
              @click:append="togglePasswordVisibility"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Yeni şifrənin təkrarı"
              filled
              required
              maxlength="20"
              minlength="6"
              v-model="formData.passwordRepeat"
              :rules="formRules.passwordRepeat"
              :type="isPasswordRepeatVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordRepeatVisible)"
              @click:append="togglePasswordRepeatVisibility"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog()">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="resetPasswordForm"
        color="success"
        depressed
      >
        Yenilə
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOldPasswordVisible: false,
      isPasswordVisible: false,
      isPasswordRepeatVisible: false,
      formValidation: false,
      formData: {
        oldPassword: "",
        password: "",
        passwordRepeat: "",
      },
      formRules: {
        oldPassword: [(v) => !!v || "Köhnə şifrəni daxil edin"],
        password: [
          (v) => !!v || "Yeni şifrəni daxil edin",
          (v) =>
            (!!v && v.length >= 6 && v.length <= 20) ||
            "Şifrənin uzunlugu ən az 6 və ən çox 20 simvol ola bilər",
          (v) =>
            /^(?!\.)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.#$^+=!*()@%&]).{6,20}$/.test(
              v
            ) ||
            "Şifrədə ən az bir kiçik və bir böyük hərf, bir rəqəm və ən az bir simvol olmalıdır (.#$^+=!*()@%&)",
        ],
        passwordRepeat: [
          (v) => !!v || "Yeni şifrənin təkrarını daxil edin",
          (v) =>
            (!!v && v.length >= 6 && v.length <= 20) ||
            "Şifrənin uzunlugu ən az 6 və ən çox 20 simvol ola bilər",
          (v) =>
            /^(?!\.)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.#$^+=!*()@%&]).{6,20}$/.test(
              v
            ) ||
            "Şifrədə ən az bir kiçik və bir böyük hərf, bir rəqəm və ən az bir simvol olmalıdır (.#$^+=!*()@%&)",
          (v) =>
            v === this.formData.password || "Şifrələr bir-birinə uyğun deyil",
        ],
      },
    };
  },
  methods: {
    toggleOldPasswordVisibility() {
      this.isOldPasswordVisible = !this.isOldPasswordVisible;
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    togglePasswordRepeatVisibility() {
      this.isPasswordRepeatVisible = !this.isPasswordRepeatVisible;
    },
    getPasswordInputIcon(isVisible) {
      return isVisible ? "mdi-eye" : "mdi-eye-off";
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
  },
};
</script>
