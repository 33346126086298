<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" lg="4">
        <v-card>
          <v-card-text class="text-center pa-7">
            <img
              :alt="userData.fullName"
              :src="
                profilePhoto != null
                  ? `${profilePhoto}`
                  : userData != null && userData.imageUrl != null
                  ? `${userData.imageUrl}`
                  : 'https://toppng.com/uploads/preview/avatar-png-115540218987bthtxfhls.png'
              "
              width="150px"
              class="img-fluid rounded-circle shadow-sm"
            />

            <h4
              class="mt-2 title blue-grey--text text--darken-2 font-weight-regular"
            >
              {{
                userData != null && userData.firstName != null
                  ? userData.firstName
                  : ""
              }}
              {{
                userData != null && userData.lastName != null
                  ? userData.lastName
                  : ""
              }}
            </h4>
            <h6 class="subtitle-2 font-weight-light">
              {{
                userData != null && userData.position != null
                  ? userData.position
                  : "-"
              }}
            </h6>
            <v-btn
              color="primary"
              class="mt-4"
              depressed
              @click="toggleChangePasswordDialog"
            >
              <v-icon class="mr-2" medium>
                mdi-shield-lock
              </v-icon>
              <span>
                Şifrəni yenilə
              </span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="8">
        <v-card>
          <v-card-text>
            <h3
              class="title blue-grey--text text--darken-2 font-weight-regular"
            >
              Hesab məlumatları
            </h3>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="userData.fullName"
              label="A.S.A."
              filled
              readonly
              background-color="transparent"
            ></v-text-field>
            <v-text-field
              v-model="userData.userName"
              label="İstifadəçi adı"
              filled
              readonly
              background-color="transparent"
            ></v-text-field>
            <v-text-field
              v-model="userData.position"
              label="Vəzifə"
              filled
              background-color="transparent"
            ></v-text-field>

            <v-text-field
              type="email"
              v-model="userData.emailAddress"
              label="Email ünvanı"
              filled
              readonly
              background-color="transparent"
            ></v-text-field>
            <v-text-field
              v-model="userData.phoneNumber"
              label="Əlaqə nömrəsi"
              filled
              readonly
              background-color="transparent"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="changePasswordDialog"
      persistent
      scrollable
      max-width="1000px"
    >
      <ResetPasswordForm
        @formSubmit="handleResetPassword"
        :toggleDialog="toggleChangePasswordDialog"
        :isSubmittingForm="isUpdatingPassword"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ResetPasswordForm from "../Users/components/ResetPasswordForm/index.vue";
import { mapGetters } from "vuex";
import API from "@/services";
import bus from "../../../event-bus";

export default {
  name: "Profile",
  data: () => ({
    changePasswordDialog: false,
    isUpdatingPassword: false,
    profilePhoto: null,
  }),
  components: { ResetPasswordForm },
  computed: {
    ...mapGetters({
      userData: "getUserData",
    }),
  },
  methods: {
    toggleChangePasswordDialog() {
      this.changePasswordDialog = !this.changePasswordDialog;
    },
    handleResetPassword(formData) {
      this.isUpdatingPassword = true;
      API.resetPassword(formData)
        .then(() => {
          this.toggleChangePasswordDialog();
          window.location.reload();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Şifrəni yeniləmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isUpdatingPassword = false;
        });
    },
    getProfilePhoto() {
      let response = API.fetchHRProfilePhoto(this.userData.employeeId, 0, 0);
      response
        .then((res) => {
          this.profilePhoto = res;
        })
        .catch(() => {
          this.profilePhoto = null;
        });
    },
  },
  created() {
    this.getProfilePhoto();
  },
};
</script>
